import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  sendEmail(name, phone, message, email){

    var headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.post<any>('email', {name,phone,message,email}, {headers:headers});
  }
  sendStartForm(formData){

    var headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.post<any>('startEmail', {...formData}, {headers:headers});
  }


}
