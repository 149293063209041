import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule, MatCardModule, MatNativeDateModule, MatBadgeModule } from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';



import {RouterModule, Routes} from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { NgsRevealModule } from "ngx-scrollreveal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlashMessagesModule } from 'angular2-flash-messages';
import { SuccessComponent } from './success/success.component';
import { FullStackComponent } from './components/full-stack/full-stack.component';
import { StaticComponent } from './components/static/static.component';
import { CarouselModule, WavesModule } from 'angular-bootstrap-md';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component'
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import { VlomniComponent } from './components/vlomni/vlomni.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AvacComponent } from './components/avac/avac.component';
import { RoaComponent } from './components/roa/roa.component';
import { CasestudiesComponent } from './components/casestudies/casestudies.component';
import { StartComponent } from './components/start/start.component';
import {MatChipsModule} from '@angular/material/chips';
import { HttpClientModule } from '@angular/common/http';
import { BookxchangeComponent } from './components/bookxchange/bookxchange.component';
const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'app', component: FullStackComponent},
  {path: 'static-app', component: StaticComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'success', component: SuccessComponent},
  {path: 'data', component: DataAnalyticsComponent},
  {path: 'vlomni', component: VlomniComponent},
  {path: 'avac', component: AvacComponent},
  {path: 'roa', component: RoaComponent},
  {path: 'case-studies', component: CasestudiesComponent},
  {path: 'start', component: StartComponent},
  {path: 'bookxchange', component: BookxchangeComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    SuccessComponent,
    FullStackComponent,
    StaticComponent,
    DataAnalyticsComponent,
    VlomniComponent,
    AvacComponent,
    RoaComponent,
    CasestudiesComponent,
    StartComponent,
    BookxchangeComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserModule.withServerTransition({
      appId: 'develow'
  }),
    BrowserAnimationsModule,
    MatButtonModule, MatCheckboxModule, MatChipsModule, MatBadgeModule,
    NgsRevealModule,
    FormsModule,
    WavesModule,
    CarouselModule,
    FlashMessagesModule.forRoot(),
    MatInputModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LottieAnimationViewModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
