import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roa',
  templateUrl: './roa.component.html',
  styleUrls: ['./roa.component.css']
})
export class RoaComponent implements OnInit {
  public lottieConfig: Object;
  public lottieConfig2: Object;
  constructor() { 
    this.lottieConfig = {

      path: '../../../assets/lottie/5484-something-went-wrong.json',
  
      autoplay: true,
      loop: true
  };
    this.lottieConfig2 = {
      path: '../../../assets/lottie/4762-food-carousel.json',
  
      autoplay: true,
      loop: true
  };
  }
  
  ngOnInit() {
  }

}
