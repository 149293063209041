import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FlashMessagesService } from '../../../../node_modules/angular2-flash-messages';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-static',
  templateUrl: './static.component.html',
  styleUrls: ['./static.component.css']
})
export class StaticComponent implements OnInit {
  name: string;
  phone: string;
  email: string;
  message: string;
  constructor(private dataService: DataService, private flashMessage: FlashMessagesService, private router: Router) { }

  ngOnInit() {
  }

  sendEmail(){
    if(this.name == '' || this.name == undefined){

      this.flashMessage.show('Please fill in your name', { cssClass: 'alert-warning', timeout: 3000 });
      return
    }
    if(this.message == '' || this.message == undefined){
      this.flashMessage.show('Please fill in a message', { cssClass: 'alert-warning', timeout: 3000 });
      return
    }
    if(this.email == '' || this.email == undefined){
      this.flashMessage.show('Please fill in your email', { cssClass: 'alert-warning', timeout: 3000 });
      return
    }
    this.dataService.sendEmail(this.name, this.phone, "STATIC WEBSITE INQUIRY: " +this.message, this.email).subscribe(res => {
      if(res.success){
        this.flashMessage.show(res.msg, { cssClass: 'alert-success', timeout: 3000 });
        this.router.navigate(['/success'])
      } else {
        this.flashMessage.show(res.msg, { cssClass: 'alert-danger', timeout: 3000 });
      }
    })
  }

}
