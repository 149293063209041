import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vlomni',
  templateUrl: './vlomni.component.html',
  styleUrls: ['./vlomni.component.css']
})
export class VlomniComponent implements OnInit {
  public lottieConfig: Object;
  public lottieConfig2: Object;
  private anim: any;
  private animationSpeed: number = 1;
  constructor() { 
    this.lottieConfig = {
      path: '../../../assets/lottie/4272-data-dashboard.json',
  
      autoplay: true,
      loop: true
  };
    this.lottieConfig2 = {
      path: '../../../assets/lottie/1055-world-locations.json',
  
      autoplay: true,
      loop: true
  };
  }

  ngOnInit() {
  }

  handleAnimation(anim: any) {
    this.anim = anim;
}

stop() {
    this.anim.stop();
}

play() {
    this.anim.play();
}

pause() {
    this.anim.pause();
}

setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
}

}
