import { Component } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

// declare ga as a function to set and sent the events


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    public router: Router
  ) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
        console.log("sent")

      }

    });
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      // let scrollToTop = window.setInterval(() => {
      //   let pos = window.pageYOffset;
      //   if (pos > 0) {
      //     window.scrollTo(0, pos - 200); // how far to scroll on each step
      //   } else {
      //     window.clearInterval(scrollToTop);
      //   }
      // }, 16);
      window.scrollTo(0, 0)
    }
  }
}
