import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  public lottieConfig: Object;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  store: Array<any>;
  cart: Array<any>;
  totalPrice: number;
  promoCode: string;
  customFeatureName: string;
  customFeatureDescription: string;

  name: string;
  email: string;
  phone: string;
  introduceApp: string;
  completionDate: Date;
  uiuxconsiderations: string;
  notes: string;
  


  constructor(private _formBuilder: FormBuilder, private dataService: DataService, public flashMessage: FlashMessagesService, public router: Router) {
    this.totalPrice = 0;
    this.promoCode = ""
    this.lottieConfig = {
      path: '../../../assets/lottie/196-material-wave-loading.json',

      autoplay: true,
      loop: true
    };
    this.cart = [

    ]
    this.store = [
      {
        name: "Authentication",
        description: "User registration and login through Amazon Cogito or OAuth with Social Federation capabilities ( google login, facebook login)",
        price: 5000,
        image: ""
      },
      {
        name: "Social features",
        description: "User biographies, profile pictures",
        price: 5000,
        image: ""
      },
      {
        name: "Real-time capabilities",
        description: "Real time and reactive streaming connection to your server ",
        price: 5000,
        image: ""
      },
      {
        name: "Messenger",
        description: "In-app messaging system",
        price: 5000,
        image: ""
      },
      {
        name: "Admin panel",
        description: "Admin accessible only dashboard to manage : users, inventory, etc.",
        price: 5000,
        image: ""
      },
      {
        name: "Analytics Dashboard",
        description: "Dashboard displaying metrics and graphs",
        price: 5000,
        image: ""
      },
      {
        name: "Forecasting/predictive analytics",
        description: "Forecast into the future and detect anomalies with machine learning.",
        price: 5000,
        image: ""
      },
      {
        name: "Authentication",
        description: "User registration and login through Amazon Cogito or OAuth with Social Federation capabilities ( google login, facebook login)",
        price: 5000,
        image: ""
      },
      {
        name: "Intelligent email reports",
        description: "Scheduled concise summaries of KPIs and metrics",
        price: 3000,
        image: ""
      },
      {
        name: "Payment system",
        description: "Accept all major credit cards and payment gateways such as paypal, gpay, apple pay.",
        price: 5000,
        image: ""
      },
      {
        name: "Integration",
        description: "Select this component if you need develow to integrate fully or partially into pre-existing ecosystems.",
        price: 5000,
        image: ""
      },
      {
        name: "Database",
        description: "Cloud-based data lake and warehouse solutions",
        price: 5000,
        image: ""
      },
      {
        name: "Blockchain",
        description: "Leverage blockchain technologies.",
        price: 10000,
        image: ""
      },
      {
        name: "Computer vision",
        description: "Give your application the ability to understand the content of images and videos",
        price: 10000,
        image: ""
      },
  

      {
        name: "Machine learning and big data analytics",
        description: "Give your application the ability to recognize patterns ",
        price: 10000,
        image: ""
      },
      {
        name: "Location services",
        description: "Access device location, google maps, gps capabilities",
        price: 5000,
        image: ""
      },
 
      {
        name: "Camera and photo gallery capabilities",
        description: "Native access to camera and photo gallery, coupled with AWS S3 bucket storage.",
        price: 5000,
        image: ""
      },
      {
        name: "IoT",
        description: "Connect and interface with your IoT devices",
        price: 10000,
        image: ""
      },
      {
        name: "eCommerce",
        description: "Online store with full range of backend capabilities (adding/editing/removing/etc)",
        price: 10000,
        image: ""
      }
    ]
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  addCustomFeatureToCart(){
    console.log(this.customFeatureDescription)
    var item = {
      type: "custom",
      name: this.customFeatureName,
      description: this.customFeatureDescription
    }
    this.cart.push(item);
  }

  addToCart(item) {
    this.cart.push({type:"standard",...item});
    this.totalPrice += item.price;
    for (var i = 0; i < this.store.length; i++) {
      if (this.store[i].name == item.name) {
        console.log(this.store[i]);
        this.store.splice(i, 1)
      }
    }

  }
  removeFromCart(item) {
    if(item.type == "custom") {
      for (var i = 0; i < this.cart.length; i++) {
        if (this.cart[i].name == item.name) {
          console.log(this.cart[i]);
          this.cart.splice(i, 1)
        }
      }
    } else {
      this.store.unshift(item)
      this.totalPrice -= item.price;
      for (var i = 0; i < this.cart.length; i++) {
        if (this.cart[i].name == item.name) {
          console.log(this.cart[i]);
          this.cart.splice(i, 1)
        }
      }
    }
  

  }

  checkPromo() {
    if (this.promoCode == "DEVELOW10") {
      this.totalPrice *= 0.9;
    }
  }

  submitForm(){
 
    console.log(this.name)
    console.log(this.completionDate)
    console.log(this.phone)
    console.log(this.uiuxconsiderations)
    console.log(this.notes)
    console.log(this.notes)
    console.log(this.introduceApp)
    console.log(this.cart)
    this.dataService.sendStartForm({
      email: this.email,
      completionDate: this.completionDate,
      phone: this.phone,
      uiuxconsiderations: this.uiuxconsiderations,
      notes: this.notes,
      introduceApp: this.introduceApp,
      cart: this.cart
    }).subscribe(res => {
      if(res.success){
        this.flashMessage.show(res.msg, { cssClass: 'alert-success', timeout: 3000 });
        this.router.navigate(['/success'])
      } else {
        this.flashMessage.show(res.msg, { cssClass: 'alert-danger', timeout: 3000 });
      }
    })
  }

}
