import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from '../../../../node_modules/angular2-flash-messages';
import { DataService } from '../../services/data.service';
import { Router } from '../../../../node_modules/@angular/router';
import { LottieOptions, AnimationItem } from 'ngx-lottie';


declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HomeComponent implements OnInit {
  name: string;
  message: string;
  email: string;
  phone: string;
  toggle1: boolean;
  toggle2: boolean;
  slide: number;
  windowWidth: number;
  elem: any;
  images: any;

  data: Array<any>;
  slideshowContainer: any;

  public lottieConfig: any;
  public lottieConfig2: any;
  public options: LottieOptions = {
    path: '../../../assets/lottie/questionMark.json'
  };

  public getStyles = () => {
    if (this.slide == 0) {
      return {
        'background-image': 'url(../../../assets/background1.png)',
        'background-size': 'cover'
      };
    }
    if (this.slide == 1) {
      return {
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-image': 'url(../../../assets/book-app.jpg)',


      }
    }
    if (this.slide == 2) {
      return {
        'background': '#1b1b1b',
        'background-size': 'cover'

      }
    }
    if (this.slide == 3) {
      return {
        'background': '#2980b9',
        'background-size': 'cover'
      };
    }
  }


  constructor(private flashMessage: FlashMessagesService, private dataService: DataService, public router: Router) {
   

    
    this.lottieConfig = {
      path: '../../../assets/lottie/196-material-wave-loading.json',

      autoplay: true,
      loop: true
    };

    this.lottieConfig2 = {
      path: '../../../assets/lottie/719-loading.json',

      autoplay: true,
      loop: true
    };

    this.toggle1 = false
    this.toggle2 = true
    this.slide = 0;
    this.data = [
      {
        name: "ROA",
        description: "Restaurant ordering and management system",
        images: [
          "../../../assets/roa1.png",
          "../../../assets/roa2.png"
        ],
        logo: "../../../assets/roa.png",
        color: "#e74c3c",
        background: "https://www.magnetic-access.com/files/data-DE/startseite/slider/slide-1.jpg"
      },
      {
        name: "Bookxchange",
        description: "Crafting a dynamic solution for students to exchange used books.",
        images: [
          "../../../assets/bookxchangePhones.png"

        ],
        logo: "../../../assets/yorku.png",
        color: "#e74c3c",
        background: "https://www.magnetic-access.com/files/data-DE/startseite/slider/slide-1.jpg"
      },
      // {
      //   name: "AVAC",
      //   description: "Automated parking management system",
      //   images: [
      //     "../../../assets/avac1.png",
      //     "../../../assets/avac2.png"
      //   ],
      //   logo: "../../../assets/avac.png",
      //   color: "#1b1b1b",
      //   background: "https://www.magnetic-access.com/files/data-DE/startseite/slider/slide-1.jpg"
      // },
      {
        name: "VLOmni",
        description: "Real time data analytics",
        images: [
          "../../../assets/vlomni.png"
        ],
        logo: "../../../assets/vlColored.png",
        color: "#2980b9",
        background: "https://www.magnetic-access.com/files/data-DE/startseite/slider/slide-1.jpg"
      }
    ]
  }




  onResize(event) {

    this.windowWidth = event.target.innerWidth;
    if (this.windowWidth < 737) {
      this.elem = document.getElementById("myBarMobile");
      this.images = document.getElementById("imagesMobile");
    } else {
      this.elem = document.getElementById("myBar");
      this.images = document.getElementById("images");
    }
    this.slideshowContainer = document.getElementById("slideshowContainer");

  }

  ngOnInit(){
    if (window.innerWidth < 992) {
      this.elem = document.getElementById("myBarMobile");
      this.images = document.getElementById("imagesMobile");


    } else {
      this.elem = document.getElementById("myBar");
      this.images = document.getElementById("images");
    }
    this.slideshowContainer = document.getElementById("slideshowContainer");
    this.slideshow()
  }


  slideshow = () => {
    console.log("fkldsjaflkasjflksdajf:", this.elem)

    var width = 1;



    var id = setInterval(() => {

      if (width >= 100) {
        clearInterval(id);
        this.next()
        return
      }

      else {
        width += 0.25;
        this.elem.style.width = width + '%';
        if (width > 90) {
          var dynamicOpacity = (100 - width) / 10
          var dynamicBackground = (100 - width) / 5
          var dynamicStringBackground = dynamicBackground.toString()
          var dynamicStringOpacity = dynamicOpacity.toString();
          if (this.images) {

            this.images.style.opacity = dynamicStringOpacity;
          }
          this.slideshowContainer.style.opacity = dynamicStringBackground;
        } else {
          var newOpacity = width / 10

          var newOpacityString = newOpacity.toString()

          this.slideshowContainer.style.opacity = newOpacityString
        }
      }
    }, 10);


  }

  next() {
    if (this.slide < 2) {
      this.slideshow()
      this.slide += 1
    } else {
      this.slideshow()

      this.slide = 0
    }

  }


  toggleFirst() {
    this.toggle1 = !this.toggle1
  }

  toggleSecond() {
    this.toggle2 = !this.toggle2
  }

  sendEmail() {
    if (this.name == '' || this.name == undefined) {

      this.flashMessage.show('Please fill in your name', { cssClass: 'alert-warning', timeout: 3000 });
      return
    }
    if (this.message == '' || this.message == undefined) {
      this.flashMessage.show('Please fill in a message', { cssClass: 'alert-warning', timeout: 3000 });
      return
    }
    if (this.email == '' || this.email == undefined) {
      this.flashMessage.show('Please fill in your email', { cssClass: 'alert-warning', timeout: 3000 });
      return
    }
    this.dataService.sendEmail(this.name, this.phone, this.message, this.email).subscribe(res => {
      if (res.success) {
        this.flashMessage.show(res.msg, { cssClass: 'alert-success', timeout: 3000 });
        this.router.navigate(['/success'])
      } else {
        this.flashMessage.show(res.msg, { cssClass: 'alert-danger', timeout: 3000 });
      }
    })
  }

}
