import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avac',
  templateUrl: './avac.component.html',
  styleUrls: ['./avac.component.css']
})
export class AvacComponent implements OnInit {

  public lottieConfig: Object;
  public lottieConfig2: Object;
  constructor() { 
    this.lottieConfig = {

      path: '../../../assets/lottie/3532-car.json',
  
      autoplay: true,
      loop: true
  };
    this.lottieConfig2 = {
      path: '../../../assets/lottie/1758-credit-card.json',
  
      autoplay: true,
      loop: true
  };
  }
  
  ngOnInit() {
  }
}
