import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookxchange',
  templateUrl: './bookxchange.component.html',
  styleUrls: ['./bookxchange.component.css']
})
export class BookxchangeComponent implements OnInit {
  public lottieConfig: Object;
  public lottieConfig2: Object;
  constructor() { 
    this.lottieConfig = {

      path: '../../../assets/lottie/3151-books.json',
  
      autoplay: true,
      loop: true
  };
    this.lottieConfig2 = {
      path: '../../../assets/lottie/4762-food-carousel.json',
  
      autoplay: true,
      loop: true
  };
  }
  
  ngOnInit() {
  }

}